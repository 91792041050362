import { request } from "@/request/http";

/**
 * @name: 作业页接口
 * @author: camellia
 * @date: 2022-02-28 
 */
export default {
    // 获取报告列表
    getReportList: (data) => {
        return request("POST", `/index.php/studentReport/getReportList`, data);
    },
};