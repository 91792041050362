import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { startLoading, endLoading, sleep } from "@/utils/function";
import { 
    domainUrl, 
    courseSorcePageSize,
} from "@/utils/const";
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import API from "@/api/student/report/reportList"
export default {
    name: "other",
    // 页面组件
    components: {
        VueUeditorWrap,
    },
    inject:['reload'],
    data() {
        return {
            // =======================================================
            // 左侧项目列表 数据
            // 已删除班级显示标识
            deleteCourseShowSign: false,
            activeName: "first",
            // 报告试题列表
            reportList: [],
            currentCourse_id: 0,
            // 课程id
            project_id: "",
            rightContentShow: true,
            update_status: 0,
            // 虚拟数据
            virtual_data: [],
            questionList: [],
            dialogShow: false,
            // 弹窗标题
            dialogTitle: "",
            dialogSign: "",
            // 用户id
            user_id: "",
            // 报告状态
            report_status:0,
            // 报告模板附件链接
            report_file_url:'',
            // 搜索关键词
            input:'',
            page:1,
            pagesize:9,
            dataTotal:'',
            submitList:[
                {
                    id:0,
                    name:'全部'
                },
                {
                    id:1,
                    name:'未提交'
                },
                {
                    id:2,
                    name:'已提交'
                }
            ],
            // 提交状态
            submitStatus:0
        };
    },
    created() {
        const self = this;
        self.getReportList();
    },
    methods: {
        indexMethod(index)
        {
            let currentPage = this.page; // 当前页码
            let pageSize = this.pagesize; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
        gotoANswer(info)
        {
            let self = this;
            self.$router.push({
                path: "/student/reportDetail",
                query: {
                    name: info.name,
                    project_id:info.project_id,
                    hideMenu:1,
                    type:1
                }
            })
            .then(()=>{
                self.reload()
            })
        },
        downFile()
        {
            window.open(this.report_file_url);
        },
        getReportList() 
        {
            const self = this;
            let data = {
                search:self.input,
                state:self.submitStatus,
                page:self.page,
                pagesize:self.pagesize
            };
            API.getReportList(data)
                .then((result)=>{
                    if (result.code == 1) 
                    {
                        self.reportList = result.data;
                        self.dataTotal = result.number
                    } 
                    else if (result.code == 10) 
                    {
                        // Message.warning(result.msg);
                        this.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            button:[
                                {
                                    type:1,
                                    text:'知道了'
                                }
                            ]
                        })
                        return;
                    }
                })
                .catch((err) => {

                });
        },
    },
};
